import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const Degerlerimiz = () => {
    const ref = useRef(null); // Elementi referans al
    const { scrollYProgress } = useScroll({ target: ref }); // Hedef olarak bu elementi belirle

    // Scroll oranına göre X pozisyonunu sağdan sola hareket ettir
    const x1 = useTransform(scrollYProgress, [0, 1], ['75%', '-20%']);
    const x2 = useTransform(scrollYProgress, [0, 1], ['45%', '-20%']);
    const x3 = useTransform(scrollYProgress, [0, 1], ['25%', '-20%']);

    const textAnimation = (delay) => ({
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 1, delay } }
    });
    const imageAnimation = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 1 } }
    };

    return (
        <div ref={ref} className='overflow-x-hidden calculate-h-screen'>
            <div className='col-7-container'>
                <div className='col-7-container-inner py-24 grid grid-cols-1 md:grid-cols-2 gap-16'>
                    <motion.div className='flex flex-col text-white space-y-8'>
                        {/* Hakkımızda */}
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            variants={textAnimation(0)} // İlk animasyon hemen başlar
                        >
                            <h1 className='text-45 font-bold mb-8'>Hakkımızda</h1>
                            <div className='space-y-4'>
                            <p className='text-16 leading-6'>
                            Misura Teknoloji olarak, alüminyum işleme makineleri ve CNC tezgâhları üretiminde sektörde öncü bir firmayız. Gelişmiş üretim altyapımız ve modern kalite kontrol süreçlerimizle, uluslararası standartlara uygun, yüksek performanslı ve dayanıklı ürünler sunuyoruz.
                            </p>
                            <p className='text-16 leading-6'>
                            Üretim süreçlerimizde, geleneksel mühendislik uzmanlığını en ileri teknolojiyle birleştiriyoruz. Bu sayede, sektörde güvenilirlik ve uzun ömürlülük konularında referans kabul edilen makinelerle, müşteri odaklı yenilikçi çözümler geliştiriyor ve sektördeki standartları yeniden tanımlıyor, yüksek kaliteli ve son teknoloji makinelerimiz ile müşterilerimizin operasyonel verimliliğini artırmayı hedefliyoruz. Sektördeki konumumuzu, küresel satış ağımız ve alanında uzman ekibimizle dünya çapında sunduğumuz kapsamlı hizmetlerle pekiştiriyoruz.

                            </p>
                            <p className='text-16 leading-6'>Müşteri memnuniyetini her zaman ön planda tutuyor, satış sonrası destek hizmetlerimizle fark yaratıyoruz. 7/24 sağladığımız uzaktan bağlantı ve yerinde servis hizmetlerimizle, müşterilerimizin ihtiyaçlarına hızla yanıt veriyor ve işletme süreçlerinin kesintisiz devamlılığını sağlıyoruz.

</p >
<p className='text-16 leading-6'>Misura Teknoloji olarak, endüstriyel makine teknolojilerinde öncü rolümüzü koruyarak, küresel ölçekte kalıcı bir etki yaratmayı ve sektördeki konumumuzu sürekli olarak güçlendirmeyi hedefliyoruz.</p>
</div>
                        </motion.div>

                        {/* Vizyon */}
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            variants={textAnimation(0.5)} // İkinci animasyon 0.5 saniye gecikir
                        >
                            <h1 className='text-45 font-bold mb-8'>Vizyon</h1>
                            <p className='text-16  leading-6'>
                            Üstün mühendislik ve detaylara verdiğimiz özenle ürettiğimiz her makine, sizlere kusursuz bir hizmet kalitesi ve profesyonel uzmanlık sunmak için tasarlanmıştır. Misura Teknoloji olarak tüm ürün ve servis ihtiyaçlarınızı karşılamak, satış öncesi ve sonrası süreçlerde size özel, yüksek standartlarda hizmet sunmak en büyük önceliğimizdir.

                            </p>
                        </motion.div>

                        {/* Misyon */}
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            variants={textAnimation(1)} // Üçüncü animasyon 1 saniye gecikir
                        >
                            <h1 className='text-45 font-bold mb-8'>Misyon</h1>
                            <p className='text-16  leading-6'>
                            PVC ve alüminyum işleme makineleri sektöründeki bilgi birikimimizi ve deneyimimizi farklı alanlara taşıyarak, tam entegre üretim tesisleri kurabilme yetkinliğine ulaşmak. Yenilikçi yazılım çözümlerimizle kalite kontrol süreçlerini en üst seviyeye çıkararak, verimlilikte ve teknolojik gelişimde sektörde öncülük ederek, sürdürülebilir başarı ve sürekli gelişim ilkemizle her alanda değer yaratmayı hedefliyoruz.
                            </p>
                        </motion.div>
                    </motion.div>

                    {/* Resim ve Hareketli Divler */}
                    <div className='overflow-hidden relative'>
                        <div className=''>
                            <h1 className='text-45 font-bold mb-8 opacity-0'>Hakkımızda</h1>
                        </div>
                        <img src='/images/degerlerimiz/1.jpg' className='object-cover w-full h-full ' alt="Hakkımızda" />
                        <div className='absolute h-full w-full z-50 top-0 left-0 flex flex-col'>
                            <div className=''>
                                <h1 className='text-45 font-bold mb-8 opacity-0'>Hakkımızda</h1>
                            </div>
                            <motion.div
                                className='h-1/3 w-full bg-white'
                                initial={{ x: 0 }}
                                whileInView={{ x: '-100%' }}
                                transition={{ duration: 1.5, ease: 'easeInOut' }}
                            ></motion.div>
                            <motion.div
                                className='h-1/3 w-full bg-white'
                                initial={{ x: 0 }}
                                whileInView={{ x: '100%' }}
                                transition={{ duration: 1.5, ease: 'easeInOut' }}
                            ></motion.div>
                            <motion.div
                                className='h-1/3 w-full bg-white'
                                initial={{ x: 0 }}
                                whileInView={{ x: '-100%' }}
                                transition={{ duration: 1.5, ease: 'easeInOut' }}
                            ></motion.div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Scroll ile Hareket Eden Yazılar */}
            <div className='py-32 flex flex-col justify-center md:my-40 space-y-10 md:space-y-36'>
                <motion.div style={{ x: x1 }} className=''>
                    <h5 className='text-[8.6vw] font-thin text-[#707070] block w-full text-nowrap'>
                        SONUÇ ODAKLIYIZ
                    </h5>
                </motion.div>

                <motion.div style={{ x: x2 }} className=''>
                    <h5 className='text-[8.6vw] font-thin text-[#707070] block w-full text-nowrap'>
                        DUYARLIYIZ
                    </h5>
                </motion.div>

                <motion.div style={{ x: x3 }} className=''>
                    <h5 className='text-[8.6vw] font-thin text-[#707070] block w-full text-nowrap'>
                        SAYGILIYIZ
                    </h5>
                </motion.div>
            </div>

            <div className='col-7-container'>
                <div className='col-7-container-inner py-24 grid grid-cols-1 md:grid-cols-2 gap-16'>

                    {/* Resmin olduğu kısım */}
                    <motion.div
                        className='flex flex-col text-white space-y-8'
                        initial="hidden"
                        whileInView="visible"
                        variants={imageAnimation}  // Resme animasyon uygulanıyor
                    >
                        <img src='/images/degerlerimiz/emrah-kayadibi.png' className='object-contain' />
                    </motion.div>

                    {/* Yazılar */}
                    <div className='text-white flex flex-col justify-center w-[100%] md:w-[60%]'>
                        <motion.h1
                            className='text-45 font-bold mb-10'
                            initial="hidden"
                            whileInView="visible"
                            variants={textAnimation(0)}  // Başlık için animasyon (sıfır gecikme)
                        >
                            EMRAH KAYADİBİ
                        </motion.h1>
                        <motion.p
                            className='text-16  leading-6 text-white mb-8'
                            initial="hidden"
                            whileInView="visible"
                            variants={textAnimation(0.5)}  // İlk paragraf için animasyon (0.5 saniye gecikme)
                        >
                            2018 yılında İstanbul’da kurduğum Misura Teknoloji ile, makine sektöründeki deneyimimi genç ve dinamik ekibimle birleştirerek sizlere hizmet sunma hedefiyle yola çıktım. Amacım, ülkemize değer katmak ve işimizi şeffaf bir yönetim anlayışıyla yürütmek.
                        </motion.p>
                        <motion.p
                            className='text-16  leading-6 text-white'
                            initial="hidden"
                            whileInView="visible"
                            variants={textAnimation(1)}  // İkinci paragraf için animasyon (1 saniye gecikme)
                        >
Müşteri memnuniyeti, kalite ve hızlı çözümler her zaman önceliklerim arasında yer aldı. Sektördeki yenilikleri yakından takip ederek, sizlere en iyi hizmeti sunmaya ve birlikte daha iyi bir gelecek inşa etmeye devam ediyoruz.

                        </motion.p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Degerlerimiz;
