import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import ParticlesMain from './ParticlesMain'


const Guncelleniyor = () => {
  return (
    <div className='h-[calc(100vh-4rem)] bg-black flex justify-center items-center relative'>
      <div className='absolute '>
      <TypeAnimation
        sequence={[

          `İçerik Güncelleniyor...`,
          1000

        ]}
        cursor={false}
        speed={1}
        className='text-[2rem] font-bold leading-none text-white animate-changeOpacity1'
        style={{ fontSize: '2rem' }}

      />
      </div>


      <ParticlesMain />

    </div>
  )
}

export default Guncelleniyor