import React from 'react'
import { DATA } from '../../data/urunler-data'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'; // Importing framer-motion


const Section3 = () => {
    return (
        <div className='col-7-container'>
            <div className='col-7-container-inner  py-24 '>
                <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-[2px]  bg-[rgba(255,255,255,0.2)]'>

                    {
                        DATA.map((item, index) => {
                            return (
                                <motion.div

                                 className='bg-black'
                                >
                                    <div key={index} className='relative group cursor-pointer' >
                                        <div
                                            className={`h-full w-full relative z-10 bg-black flex flex-col justify-between   py-10
                                         
                                    ${index === 0 || index === 3 || 6? 'lg:pl-0 lg:pr-10' : ''} 
                                    ${index === 1 || index === 4 || index === 7  ? 'lg:pr-10 lg:pl-10' : ''}
                                    ${index === 2 || index === 5 || index === 8 ? 'lg:pr-0 lg:pl-10' : ''}
                                    ${index === 0 || index === 1 || index === 2 ? 'lg:pb-10 lg:pt-0' : ''}
                                    ${index === 3 || index === 4 || index === 5 ? 'lg:py-10 lg:pb-10' : ''}


                                    ${index === 0 || index === 2 || index === 4 ? 'md:pr-10' : ''}
                                    ${index === 1 || index === 3 || index === 5  || index === 7 ? 'md:pl-10' : ''}
                                    ${index === 0 || index === 1 || index === 4? 'md:pb-10 md:pt-0' : ''}
                                    ${index === 2 || index === 3 ? 'md:py-10' : ''}
                                    ${index === 4 || index === 5 ? 'md:pt-10 md:pb-0' : ''}
                                    
                                    
                                    `

                                            }

                                        >
                                            <motion.div
                                                initial={{ opacity: 0, scale: 1.1 }} // Initial animation on load
                                                whileInView={{ opacity: 1, scale: 1 }} // Animate into view
                                                transition={{ duration: 0.6 }} // Transition settings
                                                exit={{ scale: 0.95, opacity: 0 }}
                                                className=''>
                                                <div className='w-20 !h-12 inline-block mb-4'>
                                                    <img src={item.image} className='!h-12' />
                                                </div>
                                                <h2 className='text-24 font-semibold mb-4 text-misuraBlue'>{item.title}</h2>
                                                <h3 className='text-16  leading-6 font-light mb-4 text-white'>{item.subtitle}</h3>

                                                {
                                                    item.link&&
                                            
                                                <Link to={`urunler/${item.link}`} className='inline-block'>
                                                    <div className='text-12 text-white py-3 text-center bg-misuraBlue inline-block w-28 relative group-hover:bg-white group-hover:text-misuraBlue transition-all duration-300'>


                                                        Daha Fazlası İçin

                                                    </div>
                                                </Link>
                                                    }
                                            </motion.div>
                                        </div>
                                    </div>
                                </motion.div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default Section3