export const DATA = [
    {
        title: "KURUMSAL",
        data: [
            {
                title: "Tarihçe",
                link: "/degerlerimiz",
            },
            {
                title: "Misyon & Vizyon",
                link: "/degerlerimiz",
            },
            {
                title: "Değerlerimiz",
                link: "/degerlerimiz",
            },
            {
                title: "Saygılıyız",
                link: "/degerlerimiz",
            },
            {
                title: "Duyarlıyız",
                link: "/degerlerimiz",
            },
            {
                title: "Sonuç odaklıyız",
                link: "/degerlerimiz",
            },
            {
                title: "Yönetim Kadrosu ",
                link: "/degerlerimiz",
            },
        ],
    },
    {
        title: "HİZMETLERİMİZ",
        data: [
            {
                title: "Zamanında Teslimat",
                link: "/hizmetlerimiz",
            },
            {
                title: "Danışmanlık",
                link: "/hizmetlerimiz",
            },
            {
                title: "Eğitim",
                link: "/hizmetlerimiz",
            },
            {
                title: "Satış Sonrası Destek",
                link: "/hizmetlerimiz",
            },
            {
                title: "Kalite",
                link: "/hizmetlerimiz",
            },
            {
                title: "Yedek Parça Desteği",
                link: "/hizmetlerimiz",
            },
            {
                title: "Özel Teklifler",
                link: "/hizmetlerimiz",
            },
        ],
    },
    {
        title: "ÜRÜNLER",
        data: [
            {
                title: "Modern Teknoloji",
                link: "/urunler/modern-teknoloji",
            },
            {
                title: "Kopya Freze Makineleri",
                link: "/urunler/kopya-freze-makineleri",
            },
            {
                title: "Kesim Makineleri",
                link: "/urunler/kesim-makineleri",
            },
            {
                title: "Kertme Makinaları",
                link: "/urunler/kertme-makineleri",
            },
            {
                title: "CNC İşleme Merkezi",
                link: "/urunler/cnc-isleme-merkezi",
            },
            {
                title: "Atölye Ekipmanları | Lojistik Sistemleri",
                link: "/urunler/atolye-ekipmanlari-lojistik-sistemler",
            },
            {
                title: "Köşe Presi Makineleri",
                link: "/urunler/kose-presi-makineleri",
            },
            
        ],
    },
    {
        title: "SERVİS",
        data: [
            {
                title: "Servis hakkında",
                link: "/servis",
            },
            {
                title: "Sıkca Sorulan Sorular",
                link: "/servis",
            },
            {
                title: "İletişim yönlendirmesi",
                link: "/servis",
            },
        ],
    },
    {
        title: "HABERLER",
        data: [
            {
                title: "Fuarlar",
                link: "",
            },
            {
                title: "Müşteri hikayeleri",
                link: "",
            },
            {
                title: "Bloglar",
                link: "",
            },
        ],
    },
    {
        title: "İLETİŞİM",
        data: [
            {
                title: "Adres",
                link: "/iletisim",
            },
            {
                title: "Telefon",
                link: "/iletisim",
            },
            {
                title: "Mail",
                link: "/iletisim",
            },
            {
                title: "Google map",
                link: "https://maps.app.goo.gl/8aKkvAqhvv3TPGBK7",
            },
        ],
    },
];