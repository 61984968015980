import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Section5 = () => {
    return (
        <motion.div 
            className='col-7-container py-32 relative'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.6 } }}  // Exit animation
        >
            <div className='col-7-container-inner relative mb-12 font-bold'>
                <h2 className='text-45 text-white font'>Haberler & Etkinlikler</h2>
            </div>

            <div className='col-span-8 grid grid-cols-14 gap-1'>
                <div className='col-span-12 md:col-span-7 grid'>
                    <div className='grid grid-cols-7 relative'>
                        <div className='col-start-3 col-end-8 h-full'>
                            <motion.img 
                                src='/images/anasayfa/anasayfa-haber-1.jpg' 
                                className='h-full w-full object-cover'
                                initial={{ opacity: 0, scale: 0.9 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9, transition: { duration: 1.2 } }} // Exit animation
                            />
                        </div>
                        <motion.div 
                            className='absolute top-0 left-0  h-full bg-misuraBlue mix-blend-screen'
                            initial={{ opacity: 0,width:'0%' }}
                            whileInView={{ opacity: 1 ,width:'71.5%'}}
                            exit={{ opacity: 0, transition: { duration: 2 } }} // Exit animation
                        ></motion.div>
                    </div>
                </div>

                <motion.div 
                    className='col-span-2 pl-4'
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100, transition: { duration:1.2 } }} // Exit animation
                >
                    <img 
                        src='/images/anasayfa/anasayfa-haber-2.jpg' 
                        className='h-full w-full object-cover'
                    />
                </motion.div>

                <div className='col-span-full md:col-span-4 flex flex-col md:pl-8 mt-8 md:mt-0'>
                    <p className='text-16  leading-6 text-[#A1A1A0] mb-8'>
                    Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.Misura Teknoloji Ürünleri Ile Ilgili Tüm Ihtiyaçlarınızı Karşılamaya Ve Satış Veya Servis Ihtiyaçlarınız Için Üstün Ve Kişiselleştirilmiş Bir Hizmet Sunmaya Hazırız.Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.Misura Teknoloji Ürünleri Ile Ilgili Tüm Ihtiyaçlarınızı Karşılamaya Ve Satış Veya Servis Ihtiyaçlarınız Için Üstün Ve Kişiselleştirilmiş Bir Hizmet Sunmaya Hazırız.
                    </p>
                    <Link to={'/haberler-ve-etkinlikler'}>
                    <div className='text-12 text-[#0C0C0F] py-3 text-center inline-block w-28 bg-white hover:bg-misuraBlue hover:text-white object-cover transition-all duration-300 cursor-pointer'>
                        Daha Fazlası İçin
                    </div>
                    </Link>
                </div>
            </div>
        </motion.div>
    )
}

export default Section5
