import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Section4 = () => {

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0, y: -100 }, // Initial state
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }, // Animation when it comes into view
        exit: { opacity: 0, y: 100, transition: { duration: 0.8 } } // Exit animation
    }

    return (
        <motion.div
            className='col-7-container'
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            exit="exit" // Exit animation
        >
            <div className='col-7-container-inner relative'>
                <img src='/images/anasayfa/ikinciel.jpg' className='aspect-[16/9] md:aspect-[16/5] object-cover w-full'/>
                <div className='absolute h-full w-full top-0 left-0 z-10 flex justify-end flex-col py-10 px-6 md:px-20'>
                    <h4 className='text-45 font-bold mb-4 text-white'>2.El Makinalar</h4>
                    <Link to={'/ikinci-el-urunler'} className='inline-block'>
                    <div className='text-12 text-[#0C0C0F] py-3 text-center inline-block w-28 bg-white hover:bg-misuraBlue hover:text-white object-cover transition-all duration-300 cursor-pointer'>
                        Daha Fazlası İçin
                    </div>
                    </Link>
                </div>
            </div>
        </motion.div>
    )
}

export default Section4
