import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const Iletisim = () => {
    // State to control modal and loading visibility
    const [isModalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setLoading] = useState(false);

    // Formik validation schema
    const validationSchema = Yup.object({
        ad: Yup.string().required('Ad gerekli'),
        soyad: Yup.string().required('Soyad gerekli'),
        mail: Yup.string().email('Geçerli bir mail adresi girin').required('Mail gerekli'),
        sehir: Yup.string().required('Şehir gerekli'),
        ulke: Yup.string().required('Ülke gerekli'),
        mesaj: Yup.string().required('Mesaj gerekli'),
    });

    // Animasyon ayarları
    const containerVariants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.8, staggerChildren: 0.1 } },
        exit: { opacity: 0, transition: { duration: 0.5 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        exit: { opacity: 0, y: 50, transition: { duration: 0.5 } },
    };

    const errorVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    };

    // Form verilerini PHP'ye gönderme işlemi
    const handleSubmit = async (values, { setSubmitting }) => {
        setLoading(true); // Loading durumu başlasın
        try {
            const response = await fetch('https://misura.com.tr/misura-contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            const result = await response.text(); // PHP'den dönen yanıt
            console.log(result); // Yanıtı kontrol et

            setSubmitting(false);
            setLoading(false); // Loading durumu kapatılsın
            setModalMessage('Mesajınız başarıyla gönderildi!');
            setModalVisible(true); // Modal'ı göster
        } catch (error) {
            console.log('Mesaj gönderimi başarısız oldu:', error);
            setSubmitting(false);
            setLoading(false); // Loading durumu kapatılsın
            setModalMessage('Mesaj gönderiminde bir hata oluştu.');
            setModalVisible(true); // Hata mesajıyla modal'ı göster
        }
    };

    return (
        <motion.div
            className='col-7-container calculate-h-screen relative' // Relative ekleyelim ki loading ekranı burada konumlanabilsin
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
        >
            <div className='col-7-container-inner py-24'>
                <div className='grid grid-cols-3 gap-3 bg-[rgba(25,24,24,0.95)] px-12 py-16'>
                    <motion.div
                        className='col-span-5 md:col-span-1 text-white mb-16 md:mb-0 relative z-50'
                        variants={itemVariants}
                    >
                        <motion.h1 className='text-35 font-semibold mb-8'>İLETİŞİM</motion.h1>
                        <motion.a className='text-16 block mb-4' variants={itemVariants}>+90 551 408 19 07</motion.a>
                        <motion.a className='text-16 block mb-4 text-16 leading-6' variants={itemVariants}>
                            İkitelli Organize Sanayi Bölgesi
                            <br />
                            Pik Dökümcüler San. Sit. Bina No:27
                            <br />
                            TEM 34 İkitelli No:180 Pk:34490
                            <br />
                            Başakşehir / İstanbul
                        </motion.a>
                        <motion.a className='text-16 block mb-8' variants={itemVariants}>info@misura.com.tr</motion.a>

                        <motion.a target='_blank' href='https://maps.app.goo.gl/pJrirdk5uN3GiFwH8' className='block text-16 py-4 bg-white text-black inline-block font-semibold px-12 cursor-pointer hover:text-white hover:bg-misuraBlue transition-all duration-300' variants={itemVariants}>Yol Tarifi Al</motion.a>
                    </motion.div>

                    <motion.div className='col-span-5 md:col-span-2 z-50 relative' variants={containerVariants}>
                        <Formik
                            initialValues={{ ad: '', soyad: '', mail: '', sehir: '', ulke: '', mesaj: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, errors, touched }) => (
                                <Form className=''>
                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                                        <div className='grid grid-rows-5 gap-3'>
                                            <motion.div variants={itemVariants} className='relative'>
                                                <Field name="ad" placeholder='Ad' type='text' className='w-full py-4 px-4 bg-[#4A4A4A] text-16' />
                                            </motion.div>
                                            <motion.div variants={itemVariants} className='relative'>
                                                <Field name="soyad" placeholder='Soyad' type='text' className='w-full py-4 px-4 bg-[#4A4A4A] text-16' />
                                            </motion.div>
                                            <motion.div variants={itemVariants} className='relative'>
                                                <Field name="mail" placeholder='Mail' type='email' className='w-full py-4 px-4 bg-[#4A4A4A] text-16 transition-all duration-300 ' />
                                            </motion.div>
                                            <motion.div variants={itemVariants} className='relative'>
                                                <Field name="sehir" placeholder='Şehir' type='text' className='w-full py-4 px-4 bg-[#4A4A4A] text-16' />
                                            </motion.div>
                                            <motion.div variants={itemVariants} className='relative'>
                                                <Field name="ulke" placeholder='Ülke' type='text' className='w-full py-4 px-4 bg-[#4A4A4A] text-16' />
                                            </motion.div>
                                        </div>
                                        <div className='grid grid-rows-5 gap-3'>
                                            <motion.div className='row-span-4' variants={itemVariants}>
                                                <Field as="textarea" name="mesaj" placeholder='Mesaj' className='w-full h-full py-4 px-4 bg-[#4A4A4A] text-16 row-span-4' />
                                            </motion.div>
                                            <motion.div className='row-span-1' variants={itemVariants}>
                                                <button type="submit" disabled={isSubmitting} className='cursor-pointer w-full py-4 px-4 text-16 font-semibold bg-white hover:text-white hover:bg-misuraBlue flex justify-center items-center row-span-1 transition-all duration-300'>
                                                    Gönder
                                                </button>
                                            </motion.div>
                                        </div>
                                        <div className=' p-4 text-red-600 text-xs font-light'>
                                            {Object.keys(errors).length > 0 && (
                                                <ul>
                                                    {Object.keys(errors).map((key) => (
                                                        touched[key] && (
                                                            <motion.li
                                                                key={key}
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="hidden"
                                                                variants={errorVariants}
                                                            >
                                                                {errors[key]}
                                                            </motion.li>
                                                        )
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>


                                </Form>
                            )}
                        </Formik>
                    </motion.div>
                </div>
            </div>

            {/* Loading ekranı */}
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <motion.div
                        className="flex flex-col items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >

                     
                        <motion.p
                            className="text-white text-lg"
                            animate={{ opacity: [0.8, 1, 0.8] }}
                            transition={{ repeat: Infinity, duration: 2 }}
                        >
                            Mesajınız Gönderiliyor...
                        </motion.p>
                    </motion.div>
                </div>
            )}

            {/* Modal Kutusu */}
            {isModalVisible && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center  bg-[rgba(0,0,0,0.5)] z-50"
                >
                    <div className="bg-white p-8  shadow-lg text-center max-w-sm mx-auto">
                        <p className="mb-4 text-sm">{modalMessage}</p>
                        <button
                            onClick={() => setModalVisible(false)}
                            className="px-12 py-4 bg-black text-white hover:bg-misuraBlue text-sm "
                        >
                            Tamam
                        </button>
                    </div>
                </motion.div>
            )}
        </motion.div>
    );
};

export default Iletisim;
