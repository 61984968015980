import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { DATA } from '../../data/header-data';

const Dropdown = ({ items, isSubmenu = false }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <ul className={`flex ${!isSubmenu ? 'flex-col ' : 'flex flex-col w-max px-5'} justify-between w-full`}>
            {items.map((item, index) => (
                <li key={index} className="relative font-semibold flex flex-col">
                    <Link
                        to={item.link}
                        onClick={(e) => {
                            if (item.dropDown) e.preventDefault();
                            toggleDropdown(index);
                        }}
                        className={`block py-2 hover:bg-gray-200 text-lg px-1 lg:px-3 text-black ${!isSubmenu ? '' : ''}`}
                    >
                        {item.title}
                    </Link>
                    {item.dropDown && openIndex === index && (
                        <Dropdown items={item.dropDown} isSubmenu={true} />
                    )}
                </li>
            ))}
        </ul>
    );
};

const MobileHeader = ({ toggle, setToggle }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleAnimationComplete = () => {
        setShowDropdown(true); // Show dropdown after the animations finish
    };

    const handleCloseMenu = () => {
        setShowDropdown(false);
    };

    return (
        <AnimatePresence >
            {toggle && (
                <motion.div
                    className="fixed left-0 h-[calc(100svh-4rem)] w-full z-50 "
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="relative h-full">
                        {/* First section animation */}
                        <motion.div
                            initial={{ x: '-100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '-100%' }}
                            transition={{ delay: 0, duration: 0.3 }}
                            className="h-1/3 w-full bg-white"
                        ></motion.div>

                        {/* Second section animation */}
                        <motion.div
                            initial={{ x: '100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}
                            transition={{ delay: 0.1, duration: 0.3 }}
                            className="h-1/3 w-full bg-white"
                        ></motion.div>

                        {/* Third section animation */}
                        <motion.div
                            initial={{ x: '-100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '-100%' }}
                            transition={{ delay: 0.2, duration: 0.3 }}
                            className="h-1/3 w-full bg-white"
                            onAnimationComplete={handleAnimationComplete} // Trigger dropdown menu after this animation
                        ></motion.div>

                        {showDropdown && (
                            <div className="h-full w-full absolute top-0 left-0">
                                <motion.nav                             initial={{ x: '-100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '-100%' }}
                            transition={{ delay: 0.2, duration: 0.3 }} className="shadow-md h-full lg:w-[70%] w-full flex justify-between items-center px-5">
                                    <Dropdown items={DATA} />
                                </motion.nav>
                            </div>
                        )}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default MobileHeader;
