import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import Guncelleniyor from '../components/particles/Guncelleniyor';

const DATA = [
    {
        name: "CROSS 410-485",
        price: '3.000.894 TL',
        discount: '%20 İNDİRİM',
        images: ["/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png"]
    },
    {
        name: "CROSS 410-485",
        price: '3.000.894 TL',
        discount: '%20 İNDİRİM',
        images: ["/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png"]
    },
    {
        name: "CROSS 410-485",
        price: '3.000.894 TL',
        discount: '%20 İNDİRİM',
        images: ["/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png"]
    },
    {
        name: "CROSS 410-485",
        price: '3.000.894 TL',
        discount: '%20 İNDİRİM',
        images: ["/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png"]
    },
    {
        name: "CROSS 410-485",
        price: '3.000.894 TL',
        discount: '%20 İNDİRİM',
        images: ["/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png"]
    },
    {
        name: "CROSS 410-485",
        price: '3.000.894 TL',
        discount: '%20 İNDİRİM',
        images: ["/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png", "/images/ikinciel-urunler/model1/model1.png"]
    }
];

const IkinciElUrunler = () => {
    const [activeIndices, setActiveIndices] = useState(Array(DATA.length).fill(0));

    const handleSlideChange = (swiper, index) => {
        const newActiveIndices = [...activeIndices];
        newActiveIndices[index] = swiper.activeIndex;
        setActiveIndices(newActiveIndices);
    };

    return (
        <>
        <Guncelleniyor/>
        {/* <div className='col-7-container calculate-h-screen'>
            <div className='col-7-container-inner py-16'>
                <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 text-white gap-1'>
                    {DATA.map((item, index) => (
                        <div key={index} className='bg-[rgba(255,255,255,0.67)] pt-3 px-4 pb-8'>
                            <span className='text-white text-10 robotoFont font-medium bg-[#BB1E10] p-2 inline-block'>{item.discount}</span>

                            <div className='h-48 w-full'>
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                                    modules={[Autoplay]}
                                    onSlideChange={(swiper) => handleSlideChange(swiper, index)}
                                    className="h-full"
                                >
                                    {item.images.map((image, imgIndex) => (
                                        <SwiperSlide key={imgIndex}>
                                            <img src={image} alt={`slide-${imgIndex}`} className="h-full w-full object-contain" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <div className='h-5 w-full flex justify-center space-x-1 py-4'>
                                {item.images.map((_, dotIndex) => (
                                    <div
                                        key={dotIndex}
                                        className={`h-2 w-2 rounded-full ${activeIndices[index] === dotIndex ? 'bg-[#E9223D]' : 'bg-[#C6C6C6]'}`}
                                    ></div>
                                ))}
                            </div>

                            <div className='w-full flex justify-center items-center text-20 font-medium robotoFont text-black my-2'>
                                {item.name}
                            </div>

                            <div className='w-full flex justify-center items-center text-26 font-medium robotoFont text-[#BB1E10]'>
                                {item.price}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div> */}
        </>
    )
}

export default IkinciElUrunler;
