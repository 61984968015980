import React, { useState } from 'react';
import { DATA } from '../data/urunler-data';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const Urunler = () => {
  const { id } = useParams();  
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const selectedCategory = DATA.find((category) => category.link === id);

  return (
    <div className='col-7-container calculate-h-screen'>
      <div className='col-7-container-inner py-24 '>
        <div className='text-white text-45 font-bold mb-8'>{selectedCategory?.title}</div>
  
        {selectedCategory ? (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1'>
          
            {selectedCategory.products.map((product, index) => {

              const randomDelay = Math.random();
              return (
                <Link to={product.link}>
                <div className={`transition-opacity cursor-pointer group overflow-hidden duration-300 ${hoveredIndex !== null && hoveredIndex !== index ? 'opacity-40' : 'opacity-100'}` }
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                >
                <motion.div initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  exit={{ opacity: 0 }}

                  transition={{
                    duration: 0.5,
                    delay: randomDelay * 1.2
                  }}
                  key={index}
                  className='aspect-video  relative'>
                  <img
                    src={`/images/urunler/${selectedCategory.link}/${product.link}/main.jpg`}
                    alt={product.title}
                    className='absolute top-0 left-0 h-full w-full'
                  />
                  <div className='h-full w-full relative z-10 flex flex-col justify-between px-6 pt-6 pb-3'>
                    <h5 className='text-21 font-bold text-white'>
                      {product.title}
                    </h5>
                    <div  className='block'>
                      <img
                        src={'/images/icons/right-hook.svg'}
                        className='w-7 group-hover:translate-x-3  transition-transform duration-300'
                        alt="Icon"
                      />
                    </div>
                  </div>
                </motion.div>
                </div>
                </Link>
              )
            })}
          </div>
          
          
        ) : (
          <p>Category not found</p>
        )}
      </div>
    </div>
  );
};

export default Urunler;
