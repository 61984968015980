import React from 'react';
import { motion } from 'framer-motion';

const DATA = [
    {
        title: "Sektörel Çözümler",
        content: ["Makina Için Ürettiğimiz Makineler Başlıca", "Özel Projeler", "Bu Tür Durumlar Için Tavsiye Edilenler", "İletişim Yönlendirmesi", "Diğer Uygulama Alanları"]
    },
    {
        title: "Yapı İnşaat",
        content: ["Makina Için Ürettiğimiz Makineler Başlıca", "Özel Projeler", "Bu Tür Durumlar Için Tavsiye Edilenler", "İletişim Yönlendirmesi", "Diğer Uygulama Alanları"]
    },
    {
        title: "Makina",
        content: ["Makina Için Ürettiğimiz Makineler Başlıca", "Özel Projeler", "Bu Tür Durumlar Için Tavsiye Edilenler", "İletişim Yönlendirmesi", "Diğer Uygulama Alanları"]
    },
    {
        title: "Otomotiv",
        content: ["Makina Için Ürettiğimiz Makineler Başlıca", "Özel Projeler", "Bu Tür Durumlar Için Tavsiye Edilenler", "İletişim Yönlendirmesi", "Diğer Uygulama Alanları"]
    },
    {
        title: "Diğer Endüstriler",
        content: ["Makina Için Ürettiğimiz Makineler Başlıca", "Özel Projeler", "Bu Tür Durumlar Için Tavsiye Edilenler", "İletişim Yönlendirmesi", "Diğer Uygulama Alanları"]
    },
    {
        title: "Diğer Endüstriler",
        content: ["Makina Için Ürettiğimiz Makineler Başlıca", "Özel Projeler", "Bu Tür Durumlar Için Tavsiye Edilenler", "İletişim Yönlendirmesi", "Diğer Uygulama Alanları"]
    }
];

const UygulamaAlanlari = () => {

    // Animations for container and items
    const containerVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.2,
                duration: 0.8
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: -30 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };

    const lineVariants = {
        hidden: { width: '0rem' },
        visible: { width: '6rem', transition: { duration: 0.6 } }
    };

    return (
        <motion.div 
            className='col-7-container min-h-screen'
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className='col-7-container-inner py-24'>
                <motion.h1 
                    className='text-45 text-white font-bold mb-16'
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    UYGULAMA Alanları
                </motion.h1>

                <motion.div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-white gap-16'>
                    {DATA.map((item, index) => (
                        <motion.div 
                            key={index} 
                            className='space-y-3'
                            variants={itemVariants}
                        >
                            <h2 className='text-30 font-semibold'>
                                {item.title}
                            </h2>
                            
                            <motion.div 
                                className='h-px bg-[#707070]'
                                variants={lineVariants}
                            ></motion.div>

                            <div className='flex flex-col'>
                                {item.content.map((text, contentIndex) => (
                                    <motion.p 
                                        className='text-16  leading-6' 
                                        key={contentIndex}
                                        variants={itemVariants} // Add animation to each paragraph
                                    >
                                        {text}
                                    </motion.p>
                                ))}
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </motion.div>
    )
}

export default UygulamaAlanlari;
