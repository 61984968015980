import React, { useState } from 'react';
import { motion } from 'framer-motion'; // Importing framer-motion
import { Link } from 'react-router-dom';

const DATA = [
    {
        title: "AX-550-PLUS",
        subtitle: "BETTER PERFORMANCE",
        image: "/images/urunler/kesim-makineleri/ax-550-plus/main.jpg",
        link: "/urunler/kesim-makineleri/ax-550-plus"
    },
    {
        title: "POTENZA M4",
        subtitle: "BETTER PERFORMANCE",
        image: "/images/urunler/cnc-isleme-merkezi/potenza-m4/main.jpg",
        link: "/urunler/cnc-isleme-merkezi/potenza-m4"
    },
    {
        title: "CARR 03",
        subtitle: "BETTER PERFORMANCE",
        image: "/images/urunler/atolye-ekipmanlari-lojistik-sistemler/carr-03/main.jpg",
        link: "/urunler/atolye-ekipmanlari-lojistik-sistemler/carr-03   "
    },
    {
        title: "AUTO CRAB",
        subtitle: "BETTER PERFORMANCE",
        image: "/images/urunler/kose-presi-makineleri/auto-crab/main.jpg",
        link: "/urunler/kose-presi-makineleri/auto-crab"
    }
];

const Section2 = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div className='col-7-container'>
            <div className='col-7-container-inner py-24'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 '>
                    {
                        DATA.map((item, index) => {
                            return (
                                <Link to={item.link}>
                                <motion.div
                                    key={index}
                                    className='bg-black'
                                    // initial={{ opacity: 0,scale:1.1,translateX:index%2===0?-500:500}} 
                                    // whileInView={{ opacity: 1, scale:1 ,translateX:0}}
                                    // transition={{ duration: 0.6}} 
                                    // exit={{scale:0.95,opacity:0}}
                                >
                                    <div
                                        className={`aspect-video  relative group transition-opacity cursor-pointer overflow-hidden duration-300 ${hoveredIndex !== null && hoveredIndex !== index ? 'opacity-40' : 'opacity-100'}`}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <img src={item.image} className='absolute top-0 left-0 h-full w-full group-hover:scale-[1.01] transition-all duration-300' />
                                        <div className='h-full w-full relative z-10 flex flex-col justify-between px-6 pt-6 pb-6'>
                                            <div className='flex flex-col'>
                                                <h5 className='text-33 font-bold text-misuraBlue'>{item.title}</h5>
                                                <h5 className='text-15 font-light text-white'>{item.subtitle}</h5>
                                            </div>
                                            <img src={'/images/icons/right-hook.svg'} className='w-12 group-hover:translate-x-3  transition-transform duration-300 ' />
                                        </div>
                                    </div>
                                </motion.div>
                                </Link>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Section2;
