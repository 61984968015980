import React from 'react'
import Slider from '../components/anasayfa/Slider'
import Section2 from '../components/anasayfa/Section2'
import Section3 from '../components/anasayfa/Section3'
import Section4 from '../components/anasayfa/Section4'
import Section5 from '../components/anasayfa/Section5'

const Anasayfa = () => {
  return (
    <div>

        <Slider/>
        <Section2/>
        <Section3/>
        <Section4/>
        <Section5/>
        
        
    </div>
  )
}

export default Anasayfa