import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DATA } from '../../data/header-data';
import { motion, useScroll, useTransform } from 'framer-motion';
import MobileHeader from './MobileHeader';
const Dropdown = ({ items, isSubmenu = false }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    // Handle closing the dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside of the dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenIndex(null); // Close the dropdown
            }
        };

        // Add event listener to listen for click events
        document.addEventListener('click', handleClickOutside);

        return () => {
            // Clean up the event listener when component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <ul ref={dropdownRef} className={`flex ${!isSubmenu ? 'flex ' : 'flex flex-col absolute w-max top-8 '} justify-between w-full bg-black `}>
            {items.map((item, index) => (
              
                <li key={index} className="relative  w-max font-semibold bg-black w-auto flex items-center">
                   
                    <Link
                        to={item.link}
                        onClick={(e) => {
                            if (item.dropDown) e.preventDefault(); // Prevent default behavior if there's a dropdown
                            toggleDropdown(index); // Toggle the current dropdown
                        }}
                        className={`block py-2  hover:text-opacity-50 text-14 px-1 lg:px-3 transition-all duration-300 group  text-white shadow-xl ${!isSubmenu?'':''}`}
                    >
                        <div className='flex '>
                        {item.title}
                        {item.dropDown &&<img src='/images/icons/down.svg' className={`${openIndex===index?'-rotate-180':'rotate-0'} transition-all duration-300 ml-1 group-hover:opacity-50`}/>}
                        </div>
                    </Link>
                   
                    {item.dropDown && openIndex === index && (
                        <Dropdown items={item.dropDown} isSubmenu={true} />
                    )}
                </li>
                
        

      
            ))}
        </ul>
    );
};

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const [toggleLanguage, setToggleLanguage] = useState(false);


    const { scrollY } = useScroll();
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isScrollingUp, setIsScrollingUp] = useState(true);
  
    useEffect(() => {
      return scrollY.onChange((latest) => {
        if (latest > lastScrollY) {
          setIsScrollingUp(false);
        } else {
          setIsScrollingUp(true);
        }
        setLastScrollY(latest);
      });
    }, [scrollY, lastScrollY]);
  
    const headerVariants = {
      hidden: { y: -100 },
      visible: { y: 0 },
    };
  

    return (
        <motion.div
      
        >
            <div className="px-5 md:px-0 flex md:grid grid-cols-14 relative z-50 h-16 w-full overflow-x-hidden md:overflow-x-visible bg-black">
                <div className="col-start-3 col-span-1 h-full ">
                    <Link to={'/'} className='block'><img src="/images/logo/misura-blue.svg" className="h-16" /></Link>
                </div>
                <div className="col-span-7 h-full w-full hidden md:block">
                    <nav className="shadow-md h-full lg:w-[70%] w-full flex justify-between items-center">
                        <Dropdown items={DATA} />
                    </nav>
                </div>
                <div className="col-span-2 h-full items-center justify-between hidden md:flex">
                    <div className='text-misuraBlue text-14 flex space-x-2 group'>
                        <img src='/images/icons/call.svg' className='h-3.5 group-hover:opacity-50 transition-all duration-300' />
                        <a target='_blank' href='tel:05514081907'><h6  className='group-hover:opacity-50 transition-all duration-300'>0 551 408 19 07</h6></a>
                    </div>


                    <div onClick={()=>setToggleLanguage(!toggleLanguage)} className='relative cursor-pointer ' >
                        <div className='flex space-x-1 text-white px-2' >
                        <h5 className='text-16'>TR</h5>
                        <img src='/images/icons/down.svg' className={`${toggleLanguage?'-rotate-180':'rotate-0'} transition-all duration-300 ml-1`} />
                        </div>
                        <div className={`absolute   bg-black top-8 left-0 px-2 py-2 ${toggleLanguage?'block':'hidden'} text-white`}>
                        <h5 className='text-16'>EN</h5>
                        </div>
                    </div>


                </div>
                <div className='w-full h-full md:hidden flex justify-end items-center'>
                    <div onClick={() => setToggle(!toggle)} className='h-9 pr-5 relative cursor-pointer'>
                        <div className={`w-6 h-1 transition-all duration-300 bg-white absolute ${toggle ? 'top-4 rotate-45' : 'top-0'}`}></div>
                        <div className={`w-6 h-1 transition-all duration-300 absolute ${toggle ? 'top-10 -translate-x-40 bg-black' : 'top-4 bg-white'}`}></div>
                        <div className={`w-6 h-1 transition-all duration-300 bg-white absolute ${toggle ? 'top-4 -rotate-45' : 'top-8'}`}></div>
                    </div>
                </div>
            </div>
            <div className='block md:hidden '>
            <MobileHeader toggle={toggle} setToggle={setToggle}/>
            </div>


        </motion.div>
    );
};

export default Header;
