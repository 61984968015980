import { useEffect } from "react";
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PageContainer from "./pages/PageContainer";
import UygulamaAlanlari from "./pages/UygulamaAlanlari";
import Hizmetlerimiz from "./pages/Hizmetlerimiz";
import Urunler from "./pages/Urunler";
import UrunlerDetay from "./pages/UrunlerDetay";
import IkinciElUrunler from "./pages/IkinciElUrunler";
import Iletisim from "./pages/Iletisim";
import Anasayfa from "./pages/Anasayfa";
import Servis from "./pages/Servis";
import UrunlerDetay2 from "./pages/UrunlerDetay2";
import Degerlerimiz from "./pages/Degerlerimiz";
import Laser from "./components/Laser";
import HaberlerVeEtkinlikler from "./pages/HaberlerVeEtkinlikler";






function App() {
  const location = useLocation();

  return (
    <ReactLenis root>
      <div className="App relative bg-black">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageContainer />} >
              <Route path="/" element={<Anasayfa />} />
              <Route path="/uygulama-alanlari" element={<UygulamaAlanlari />} />
              <Route path="/degerlerimiz" element={<Degerlerimiz />} />
              <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} />
              <Route path="/urunler/:id" element={<Urunler />} />

              <Route path="/urunler/:id/:id2" element={<UrunlerDetay />} />
 
              <Route path="/haberler-ve-etkinlikler" element={<HaberlerVeEtkinlikler />} />
              <Route path="/ikinci-el-urunler" element={<IkinciElUrunler />} />
              <Route path="/servis" element={<Servis />} />
              <Route path="/iletisim" element={<Iletisim />} />
              <Route path="/laser" element={<Laser />} />

            </Route>
          </Routes>
        </AnimatePresence>
      </div>
    </ReactLenis>
  );
}

export default App;
