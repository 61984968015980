import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const LaserAnimation = () => {
  const [pageHeight, setPageHeight] = useState(0);
  const lasers = 7; // Lazer sayısı

  // Sayfanın yüksekliğini almak için useEffect kullanarak sayfa yüklendiğinde bu işlemi yapıyoruz
  useEffect(() => {
    // Sayfa yüksekliğini hesapla
    const handleResize = () => {
      setPageHeight(document.documentElement.scrollHeight);
    };

    // Sayfa yüklendiğinde ve pencere boyutları değiştiğinde çalışır
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup: resize event listener'ını kaldır
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Rastgele gecikme fonksiyonu
  const getRandomDelay = () => Math.random() * 1.5; // 0 ile 1.5 saniye arasında rastgele gecikme
  const getRandomDuration = () => 4 + Math.random() * 2; // Lazerin süre aralığını değiştirelim (4-6 saniye)

  // Lazerlerin konumlarını hesapla (eşit aralıklı ve sağ-sol kenar boşluklu)
  const margin = 14.232; // İlk ve son lazer arasındaki kenar boşluğu yüzde cinsinden
  const laserPosition = (index) => {
    // Eşit aralıklı lazer yerleşimi hesaplaması
    const spaceBetween = (100 - 2 * margin) / (lasers - 1);
    return margin + spaceBetween * index;
  };

  return (
   <>
      {/* 5 tane lazer oluştur */}
      {Array(lasers)
        .fill(0)
        .map((_, index) => (
          <motion.div
            key={index}
            className="bg-slate-500 absolute"
            initial={{ y: "-100%", opacity: 1 }} // Ekranın üstünden başlayacak
            animate={{ y: "100%", opacity: 0.5 }} // Ekranın altına doğru gidecek
            transition={{
              duration: getRandomDuration(), // Lazerin hareket süresi rastgele olacak
              repeat: Infinity, // Sürekli tekrarlama
              ease: "linear", // Sabit hız
              delay: getRandomDelay(), // Rastgele bir gecikme ile başlar
            }}
            style={{
              width: "1px",
              height: `${pageHeight}px`, // Lazerin yüksekliğini dinamik olarak ayarla
              filter: "blur(1px)", // Hafif bir bulanıklık efekti
              left: `${laserPosition(index)}%`, // Eşit aralıklarla ve kenar boşlukları ile yerleştir
            }}
          />
        ))}
 
    </>
  );
};

export default LaserAnimation;
