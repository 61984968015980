export const DATA = [
    {
        title: 'ANASAYFA',
        link: '/'
    },
    {
        title: 'DEĞERLERİMİZ',
        link: '/degerlerimiz',
    },


    {
        title: 'HİZMETLERİMİZ',
        // link: '/hizmetlerimiz',
        dropDown: [
            {
                title: 'UYGULAMA ALANLARI',
                link: '/uygulama-alanlari',
            },
            {
                title: 'HİZMETLERİMİZ',
                link: '/hizmetlerimiz',
            },
            {
                title: 'SERVİS',
                link: '/servis',
            },
        ]


    },
    {
        title: 'ÜRÜNLER',
        link: '/urunler',
        dropDown: [
            {
                title: 'CNC İŞLEME MERKEZİ',
                link: '/urunler/cnc-isleme-merkezi',
            },
            {
                title: 'KESİM MAKİNELERİ',
                link: '/urunler/kesim-makineleri',
            },
            {
                title: 'KÖŞE PRESİ MAKİNELERİ',
                link: '/urunler/kose-presi-makineleri',
            },

            {
                title: 'PROFİL KESİM VE İŞLEME MERKEZİ',
                link: '/urunler/profil-kesim-ve-isleme-merkezi',
            },

            {
                title: 'KOPYA FREZE MAKİNELERİ',
                link: '/urunler/kopya-freze-makineleri',
            },

            {
                title: 'KERTME MAKİNALARI',
                link: '/urunler/kertme-makineleri',
            },

            {
                title: 'ATÖLYE EKİPMANLARI | LOJİSTİK SİSTEMLER',
                link: '/urunler/atolye-ekipmanlari-lojistik-sistemler',
            },





            {
                title: 'BÜKÜM MAKİNELERİ',
                link: '/urunler/bukum-makineleri',
            },

            {
                title: 'DİĞER',
                link: '/urunler/diger',
            },









        ]


    },
    {
        title: 'İLETİŞİM',
        link: '/iletisim',


    },




]