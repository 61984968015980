import React from "react";
import {DATA} from "../data/footer-data"
import { motion, AnimatePresence } from 'framer-motion';
const Footer = () => {

    return (
        <motion.div whileInView={{}}  className="col-7-container bg-[#191818] !overflow-x-hidden z-50 relative ">
            <div className="col-7-container-inner  ">
                <div className="grid grid-cols-11 py-32">
                    <div className="col-span-11 md:col-span-1 mb-20 md:mb-0 ">
                        <img src="/images/logo/misura-white.svg" className="h-16 md:w-[60%]" />
                    </div>

                    <div className="col-span-11 md:col-span-3 mb-10 md:mb-0">
                        <div className="flex justify-between mb-6">
                            <h5 className="text-16 font-medium text-white">İLETİŞİM</h5>
                            <div className="flex space-x-5">
                                <a target='_blank' href='https://www.linkedin.com/company/misura-teknoloji%CC%87/?viewAsMember=true'  className='block h-[0.5rem] hover:scale-125 transition-all duration-300'><img src="/images/icons/social/3.svg" /></a>
                                <a target='_blank' href='https://www.instagram.com/misurateknoloji/'  className='block h-[0.5rem] hover:scale-125 transition-all duration-300'><img src="/images/icons/social/2.svg" /></a>
                                <a target='_blank' href='https://www.youtube.com/@Misurateknoloji'  className='block h-[0.5rem] hover:scale-125 transition-all duration-300'><img src="/images/icons/social/4.svg" /></a>
                                <a target='_blank' href='https://www.facebook.com/profile.php?id=61555120416007&locale=tr_TR'  className='block h-[0.5rem] hover:scale-125 transition-all duration-300'><img src="/images/icons/social/1.svg" /></a>
                            </div>
                        </div>
                        <div className="text-white text-14 font-light space-y-3 mb-10">
                            <div className="flex justify-between   ">
                                <a href="tel:+905514081907" target="_blank"  className="block hover:text-misuraBlue transition-all duration-300">+90 551 408 19 07</a>
                            </div>
                            <div className="flex justify-between    ">
                                <a href="https://maps.app.goo.gl/8aKkvAqhvv3TPGBK7" target="_blank"  className="block hover:text-misuraBlue transition-all duration-300">
                                    İkitelli Organize Sanayi Bölgesi Pik Dökümcüler San. Sit. Bina
                                    No:27 TEM 34 İkitelli No: 180 Pk: 34490 Başakşehir / İstanbul
                                </a>
                            </div>
                            <div className="flex justify-between    ">
                                <a href="mailto:info@misura.com.tr" className="block hover:scale-105 transition-all duration-300 hover:text-misuraBlue">info@misura.com.tr</a>
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className="flex flex-col text-white items-center">
                                <img src="/images/icons/footer/1.svg" className="w-10" />
                                <h6 className="text-27 font-bold">35180</h6>
                                <h6 className="text-8 font-medium">ÇALIŞMA SAATİ</h6>
                            </div>
                            <div className="flex flex-col text-white items-center">
                                <img src="/images/icons/footer/2.svg" className="w-10" />
                                <h6 className="text-27 font-bold">2450</h6>
                                <h6 className="text-8 font-medium">BİTİRİLEN PROJE</h6>
                            </div>
                            <div className="flex flex-col text-white items-center">
                                <img src="/images/icons/footer/3.svg" className="w-10" />
                                <h6 className="text-27 font-bold">1200</h6>
                                <h6 className="text-8 font-medium">GERİ DÖNÜŞ</h6>
                            </div>
                        </div>
                        
                    </div>

                    <div className="col-span-11 md:col-span-1"></div>

                    <div className="col-span-11 md:col-span-5 grid grid-cols-2 md:grid-cols-3 text-[rgba(255,255,255,0.6)] text-16 gap-5">
                        {DATA.map((section, index) => (
                            <div key={index}>
                                <h2 className="font-medium mb-4">{section.title}</h2>
                                <ul>
                                    {section.data.map((item, subIndex) => (
                                        <li key={subIndex}>
                                            <a href={item.link} className="font-light hover:text-misuraBlue transition-all duration-300">{item.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="col-span-11 md:col-span-1    justify-end items-start mt-20 md:mt-0 hidden md:flex">
                        <img src="/images/icons/up.svg" className="w-4" />
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Footer;
