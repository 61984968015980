import React from 'react';
import { motion } from 'framer-motion'; // Importing Framer Motion

const DATA = [
    {
        title: "Modern Teknoloji",
        content: "MİSURA TEKNOLOJİ, Gelişmiş Üretim Altyapısı Ve Modern Kalite Kontrol Süreçleri Ile Uluslararası Standartlara Uygun Ürünler Üretmekte, Ürünlerini Geliştirmekte Ve Ar-Ge Yatırımlarını Arttırmaktadır."
    },
    {
        title: "Kaliteli Malzeme",
        content: "MİSURA TEKNOLOJİ, Gelişmiş Üretim Altyapısı Ve Modern Kalite Kontrol Süreçleri Ile Uluslararası Standartlara Uygun Ürünler Üretmekte, Ürünlerini Geliştirmekte Ve Ar-Ge Yatırımlarını Arttırmaktadır."
    },
    {
        title: "Kali̇teli̇ Kontrol Süreci̇",
        content: "MİSURA TEKNOLOJİ, Gelişmiş Üretim Altyapısı Ve Modern Kalite Kontrol Süreçleri Ile Uluslararası Standartlara Uygun Ürünler Üretmekte, Ürünlerini Geliştirmekte Ve Ar-Ge Yatırımlarını Arttırmaktadır."
    },
    {
        title: "Uygun Fiyat Politikası",
        content: "MİSURA TEKNOLOJİ, Gelişmiş Üretim Altyapısı Ve Modern Kalite Kontrol Süreçleri Ile Uluslararası Standartlara Uygun Ürünler Üretmekte, Ürünlerini Geliştirmekte Ve Ar-Ge Yatırımlarını Arttırmaktadır."
    },
    {
        title: "Tecrübe Kadro",
        content: "MİSURA TEKNOLOJİ, Gelişmiş Üretim Altyapısı Ve Modern Kalite Kontrol Süreçleri Ile Uluslararası Standartlara Uygun Ürünler Üretmekte, Ürünlerini Geliştirmekte Ve Ar-Ge Yatırımlarını Arttırmaktadır."
    },
    {
        title: "7 GÜN / 24 SAAT DESTEK",
        content: "MİSURA TEKNOLOJİ, Gelişmiş Üretim Altyapısı Ve Modern Kalite Kontrol Süreçleri Ile Uluslararası Standartlara Uygun Ürünler Üretmekte, Ürünlerini Geliştirmekte Ve Ar-Ge Yatırımlarını Arttırmaktadır."
    }
];

const Hizmetlerimiz = () => {

    // Framer Motion variants for animation
    const containerVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.2,
                duration: 0.8
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: -30 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };

    

    return (
        <motion.div
            className='col-7-container calculate-h-screen'
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className='col-7-container-inner py-24'>
                <motion.h1
                    className='text-45 text-white font-bold mb-16'
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Hizmetlerimiz
                </motion.h1>

                <motion.div
                    className='grid grid-cols-1 md:grid-cols-2 text-white md:gap-16 space-y-6 md:space-y-0'
                    variants={containerVariants}
                >
                    {
                        DATA.map((item, index) => {
                            return (
                                <motion.div
                                    key={index}
                                    className='flex'
                                    variants={itemVariants} // Applying animation to each item
                                >
                                    <div className='!w-16 flex-none leading-[3.5rem] text-106 text-misuraBlue'>
                                        {index}
                                    </div>
                                    <div className='flex flex-col'>
                                        <h2 className='text-25 font-semibold '>
                                            {item.title}
                                        </h2>
                       
                                        <p className='text-16  leading-6 font-medium mt-4'>
                                            {item.content}
                                        </p>
                                    </div>
                                </motion.div>
                            );
                        })
                    }
                </motion.div>
            </div>
        </motion.div>
    );
}

export default Hizmetlerimiz;
